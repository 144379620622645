const countriesSellableInTuple = [
  'AE',
  'AL',
  'AO',
  'AT',
  'AU',
  'BA',
  'BE',
  'BG',
  'BR',
  'BY',
  'CA',
  'CH',
  'CO',
  'CV',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HK',
  'HR',
  'HU',
  'IE',
  'IM',
  'IN',
  'IT',
  'KE',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'ME',
  'MK',
  'MO',
  'MT',
  'MX',
  'MY',
  'NL',
  'NO',
  'NZ',
  'PH',
  'PL',
  'PT',
  'QA',
  'RO',
  'RS',
  'RU',
  'SE',
  'SI',
  'SK',
  'TH',
  'TR',
  'UA',
  'US',
  'XK',
  'ZA',
] as const;

export const countriesSellableIn = (countriesSellableInTuple as unknown) as string[];

export type SupportedCountry = typeof countriesSellableInTuple[number];
